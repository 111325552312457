import { Injectable } from '@angular/core';
import {
  CardInfoType,
  CardLimitsType,
  CardStatusType,
  CustomFreezeResponse,
  PaginatedResponse,
} from '@finxone-platform/shared/sys-config-types';
import { passActiveCardsToNativeStorage } from '@app/finxone-web-frontend/app/lib/utils/flutter.utils';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { UpdateCardManagement, UpdateCurrentCardNameInState } from '../actions/card-management.action';
import {
  FreezeCurrentCard,
  GetCardDetails,
  GetCardLimits,
  GetCards,
  UnFreezeCurrentCard,
  UpdateCurrentCardDetail,
} from '../actions/card.actions';
import { AddProgressBarStack, RemoveProgressBarStack } from '../actions/progress-bar.action';
import { CardManagementService } from '../services/card-management-service/card-management-service';
import { CardManagementState } from './card-management.state';

export interface CardStateModel {
  items: CardInfoType[];
  currentCardId: string;
  currentCardDetail: CardInfoType;
  currentCardLimits?: {
    transactionLimits?: CardLimitsType;
    withdrawalLimits?: CardLimitsType;
    monthlyTransactionLimits?: CardLimitsType;
    monthlyWithdrawalLimits?: CardLimitsType;
    dailyTransactionLimits?: CardLimitsType;
    dailyWithdrawalLimits?: CardLimitsType;
  };
}

@State<CardStateModel>({
  name: 'cards',
  defaults: {
    items: [],
    currentCardId: '',
    currentCardDetail: {} as CardInfoType,
  },
  children: [CardManagementState],
})
@Injectable()
export class CardState {
  constructor(private cardManagementService: CardManagementService, private store: Store) {}

  private flutterWebView = (window as any).flutter_inappwebview;

  @Selector()
  static getCards(state: CardStateModel) {
    return state;
  }

  @Selector()
  static getCurrentCardId(state: CardStateModel) {
    return state.currentCardId;
  }

  @Selector()
  static getCurrentCardDetail(state: CardStateModel) {
    return state.currentCardDetail;
  }

  @Action(GetCards)
  fetchCards(ctx: StateContext<CardStateModel>, action: GetCards) {
    try {
      ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetCards' }));

      return this.cardManagementService
        .getCards(action.page, action.limit, action.sortOrder, action.sortKey)
        .pipe(
          tap((cardsList: PaginatedResponse<CardInfoType>) => {
            const [firstCard] = cardsList.result.filter(
              (v) =>
                v.status === CardStatusType.ACTIVE ||
                v.status === CardStatusType.INACTIVE ||
                v.status === CardStatusType.PENDING ||
                v.status === CardStatusType.BLOCKED ||
                v.status === CardStatusType.FROZEN,
            );
            ctx.setState({
              ...ctx.getState(),
              items: cardsList.result,
              currentCardDetail: firstCard,
              currentCardId: firstCard?.id,
            });
            ctx.dispatch(new UpdateCardManagement({ cardStatus: firstCard?.status }));
            ctx.dispatch(new GetCardLimits(ctx.getState().currentCardId));
            ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCards' }));

            passActiveCardsToNativeStorage(cardsList.result); // purposely non-blocking non-async
          }),
          catchError<unknown, Observable<boolean>>((_err) => {
            ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCards' }));
            throw _err;
          }),
        );
    } catch (err) {
      ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCards' }));
      return throwError(() => err);
    }
  }

  @Action(GetCardDetails)
  fetchCardDetails(ctx: StateContext<CardStateModel>, action: GetCardDetails) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetCardDetails' }));
    return this.cardManagementService.getCardDetails(action.payload).pipe(
      tap((response) => {
        let cardList = ctx.getState().items;
        cardList = cardList.map((card) => {
          if (card.id === response.id) {
            return response;
          }
          return card;
        });

        ctx.setState({
          ...ctx.getState(),
          items: cardList,
          currentCardDetail: response,
          currentCardId: response.id,
        });

        ctx.dispatch(new UpdateCardManagement({ cardStatus: response.status }));
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCardDetails' }));
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCardDetails' }));
        throw _err;
      }),
    );
  }

  @Action(GetCardLimits)
  fetchCardLimits(ctx: StateContext<CardStateModel>, action: GetCardLimits) {
    if (!action.cardId?.length) return;
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetCardLimits' }));
    return this.cardManagementService.getCardLimits(action.cardId).pipe(
      tap((response) => {
        const monthlyTransactionLimits = response.find(
          (v) => v.trxGroup === 'TRANSACTION' && v.limitType === 'MONTHLY',
        );
        const monthlyWithdrawalLimits = response.find(
          (v) => v.trxGroup === 'ATM' && v.limitType === 'MONTHLY',
        );
        const dailyTransactionLimits = response.find(
          (v) => v.trxGroup === 'TRANSACTION' && v.limitType === 'DAILY',
        );
        const dailyWithdrawalLimits = response.find((v) => v.trxGroup === 'ATM' && v.limitType === 'DAILY');

        ctx.patchState({
          ...ctx.getState(),
          currentCardLimits: {
            transactionLimits: monthlyTransactionLimits,
            withdrawalLimits: monthlyWithdrawalLimits,
            monthlyTransactionLimits: monthlyTransactionLimits,
            monthlyWithdrawalLimits: monthlyWithdrawalLimits,
            dailyTransactionLimits: dailyTransactionLimits,
            dailyWithdrawalLimits: dailyWithdrawalLimits,
          },
        });

        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCardLimits' }));
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCardLimits' }));
        throw _err;
      }),
    );
  }

  @Action(UpdateCurrentCardDetail)
  updateCurrentCardDetail(ctx: StateContext<CardStateModel>, action: UpdateCurrentCardDetail) {
    const cardList = [...ctx.getState().items];
    const cardIndex = cardList.findIndex((card) => card.id === action.cardId);
    if (cardIndex !== -1) {
      cardList.splice(cardIndex, 1, action.cardItemInfo as unknown as CardInfoType);
    }
    ctx.patchState({
      ...ctx.getState(),
      currentCardId: action.cardItemInfo.id,
      currentCardDetail: action.cardItemInfo,
      items: cardList,
    });
    this.store.dispatch(new UpdateCardManagement({ cardStatus: action.cardItemInfo.status }));
    ctx.dispatch(new GetCardLimits(ctx.getState().currentCardId));
  }

  @Action(FreezeCurrentCard)
  freezeCurrentCard(ctx: StateContext<CardStateModel>) {
    return this.cardManagementService.freezeCard({ cardId: ctx.getState().currentCardId }).pipe(
      tap((response: CustomFreezeResponse) => {
        this.updateCardState(ctx, response.data?.status);
      }),
      map((response) => ({ success: true, data: response })),
      catchError((error) => {
        console.error('Failed to freeze card:', error);
        return of({ success: false, error });
      }),
    );
  }

  @Action(UnFreezeCurrentCard)
  unFreezeCurrentCard(ctx: StateContext<CardStateModel>) {
    return this.cardManagementService.unfreezeCard({ cardId: ctx.getState().currentCardId }).pipe(
      tap((response: CustomFreezeResponse) => {
        this.updateCardState(ctx, response.data?.status);
      }),
      map((response) => ({ success: true, data: response })),
      catchError((error) => {
        console.error('Failed to unfreeze card:', error);
        return of({ success: false, error });
      }),
    );
  }

  private updateCardState(ctx: StateContext<CardStateModel>, cardState: string) {
    const currentCardId = ctx.getState().currentCardId;
    const updatedItems = ctx
      .getState()
      .items.map((item) =>
        item.id === currentCardId ? { ...item, status: cardState as CardStatusType } : item,
      );
    // update both the current card and the card item in the list with the new state
    ctx.patchState({
      ...ctx.getState(),
      items: updatedItems,
      currentCardDetail: {
        ...ctx.getState().currentCardDetail,
        status: cardState as CardStatusType,
      },
    });
  }

  @Action(UpdateCurrentCardNameInState)
  updateCurrentCardNameInState(ctx: StateContext<CardStateModel>, action: UpdateCurrentCardNameInState) {
    const cardState = ctx.getState();
    ctx.patchState({
      ...cardState,
      currentCardDetail: {
        ...cardState.currentCardDetail,
        name: action.cardName,
      },
    });
  }
}
