import {
  InputCssConfig,
  InputFieldSettings,
  WidgetNames,
  WorkflowWidgetConfigurationAttributes,
} from '@finxone-platform/shared/sys-config-types';

const accountsListingWidgetsStyles = {
  color: true,
  hAlign: true,
  vAlign: true,
  border: true,
  fontSize: true,
  showTextBold: false,
  showTextItalic: false,
  showTextUnderline: false,
  showFontStyle: true,
  showBoxShadow: true,
  backGroundColor: true,
  disabledTextColor: false,
  disabledBgColor: false,
  showMargin: true,
  showPadding: true,
  showTextInput: false,
  showQuillInput: false,
  showImageCategoryOption: false,
  iconLibrary: false,
  isShowRHSWidgetProperty: false,
  isShowNavigationToUrl: true,
  inputFieldSettings: true,
};

export type WidgetStyle = {
  [key: string]:
    | {
        color: boolean;
        hAlign: boolean;
        vAlign: boolean;
        border: boolean;
        fontSize: boolean;
        backGroundColor: boolean;
        disabledTextColor: boolean;
        disabledBgColor: boolean;
        showMargin: boolean;
        showPadding: boolean;
        showTextInput: boolean;
        showQuillInput: boolean;
        showTextBold?: boolean;
        showTextItalic?: boolean;
        showTextUnderline?: boolean;
        showImageCategoryOption: boolean;
        iconLibrary: boolean;
        isShowRHSWidgetProperty: boolean;
        showFontStyle?: boolean;
        showBoxShadow?: boolean;
        showSelectedBorderColor?: boolean;
        showInputPlaceholderColor?: boolean;
        showInputCustomStyling?: boolean;
        showTextInputFontSize?: boolean;
        showTextInputBackgroundColor?: boolean;
        isShowNavigationToUrl?: boolean;
        animationSource?: boolean;
        animationAssetLink?: boolean;
        animationUpload?: boolean;
        animationType?: boolean;
        animationContainer?: boolean;
        showLeftRightIcon?: boolean;
        showDimensionSize?: boolean;
        showOverlay?: boolean;
        inputFieldSettings?: boolean;
        showBasicInput?: boolean;
        showPrimeNgTable?: boolean;
        showTypographyClass?: boolean;
        showTypographyClassTwo?: boolean;
        isGlobalStylingWidget?: boolean;
        showInputLabel?: boolean;
      }
    | undefined;
};

// Relevant to only the transaction history widget below
export enum TransactionHistoryTopRightOption {
  TRANSACTION_TYPE_SELECTOR = 'TRANSACTION_TYPE_SELECTOR',
  QUICK_LINK = 'QUICK_LINK',
  HIDE = 'HIDE',
}

export enum TransactionContextOptions {
  TRANSACTION = 'TRANSACTION',
  EXCHANGES = 'EXCHANGES',
  CARD_TRANSACTION = 'CARD_TRANSACTION',
}

export const WidgetStyleConst = {
  [WidgetNames.DROPDOWN]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    showOverlay: false,
  },
  [WidgetNames.BUTTON]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    showOverlay: false,
  },
  [WidgetNames.CARD]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: true,
    showOverlay: false,
    showDimensionSize: false,
  },
  [WidgetNames.MULTI_ICON_QUICK_LINK_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: true,
  },
  [WidgetNames.CONDITIONAL_WIDGET_IF_ELSE_CONFIG]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: true,
    showLeftRightIcon: true,
    showBasicInput: false,
  },
  [WidgetNames.QUICK_LINKS_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: true,
    disabledBgColor: true,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: true,
    showOverlay: true,
  },
  [WidgetNames.RESEND_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    showOverlay: false,
  },
  [WidgetNames.USER_PRIVACY_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: false,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: false,
    showBoxShadow: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.TEXT_ITEM_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },

  [WidgetNames.ACCOUNT_NUMBER]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },

  [WidgetNames.SORT_CODE]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.PAYMENT_BUTTON]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: true,
    disabledBgColor: true,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.CARD_ACTIVATION]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: false,
    fontSize: true,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: false,
    showPadding: false,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },

  [WidgetNames.MANAGE_USER_STEP_01_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: false,
    inputFieldSettings: false,
  },

  [WidgetNames.MANAGE_USER_STEP_02_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: false,
    inputFieldSettings: false,
  },
  [WidgetNames.MANAGE_USER_STEP_03_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: false,
    inputFieldSettings: true,
  },

  [WidgetNames.MANAGE_USER_ASSIGN_PERSONA]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: false,
    inputFieldSettings: true,
  },
  [WidgetNames.NAME_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: false,
    inputFieldSettings: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
  },

  [WidgetNames.ADD_EMPLOYEE_STEP_1_WIDGETS]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: true,
    inputFieldSettings: false,
  },
  [WidgetNames.ADD_EMPLOYEE_STEP_2_WIDGETS]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: true,
    inputFieldSettings: false,
  },
  [WidgetNames.USER_NAME_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: true,
    inputFieldSettings: false,
  },

  [WidgetNames.PHONE_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: false,
    inputFieldSettings: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
  },

  [WidgetNames.BIRTHDAY_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: false,
    inputFieldSettings: true,
  },

  [WidgetNames.EMAIL_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: false,
    inputFieldSettings: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
  },

  [WidgetNames.ADDRESS_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: false,
    inputFieldSettings: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
  },

  [WidgetNames.PASSWORD_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: false,
    inputFieldSettings: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
  },

  [WidgetNames.CTA_BUTTON_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: true,
    disabledBgColor: true,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },

  [WidgetNames.ACCOUNT_MONTHLY_IN_AND_OUT_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.RECENT_TRANSACTION_LIST_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.LINE_WIDGET]: {
    showMargin: true,
    color: false,
    hAlign: false,
    vAlign: false,
    border: false,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.BURNDOWN_CHART]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.IMAGE_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: true,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: true,
    showOverlay: true,
  },
  [WidgetNames.PROGRESSBAR_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.CAROUSEL_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: true,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.ACCOUNT_BALANCE_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.CARD_MANAGEMENT]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.FREEZE_CARD]: {
    color: false,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.REPLACE_CARD]: {
    color: false,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.CHANGE_PIN]: {
    color: false,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.LOST_CARD]: {
    color: false,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.ADD_BENEFICIARY_WIDGET]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    showFontStyle: true,
    showTextInputFontSize: false,
    inputFieldSettings: false,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
  },
  [WidgetNames.ACCOUNT_SELECTION_LIST_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: false,
  },
  [WidgetNames.CURRENCY_INPUT_FIELD_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    showInputPlaceholderColor: true,
    showInputCustomStyling: true,
  },
  [WidgetNames.PAYMENT_REF_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: false,
    fontSize: false,
    backGroundColor: false,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: false,
    showPadding: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: false,
    showInputPlaceholderColor: false,
    isGlobalStylingWidget: true,
    showInputLabel: true,
  },
  [WidgetNames.MANDATORY_CTA_BUTTON]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    showTextInput: true,
    disabledTextColor: true,
    disabledBgColor: true,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showLeftRightIcon: true,
  },
  [WidgetNames.ENTITY_MANDATORY_CTA_BUTTON]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    backGroundColor: true,
    showTextInput: true,
    disabledTextColor: true,
    disabledBgColor: true,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showLeftRightIcon: true,
  },
  [WidgetNames.TERMS_CONFIRMATION]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: true,
    showFontStyle: true,
    showTextInputFontSize: false,
    showTextInputBackgroundColor: true,
  },
  [WidgetNames.FORM_SUBMISSION_BUTTON]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    showTextInput: true,
    disabledTextColor: true,
    disabledBgColor: true,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showLeftRightIcon: true,
    isShowNavigationToUrl: false,
  },
  [WidgetNames.ACTION_BUTTON]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    showTextInput: true,
    disabledTextColor: true,
    disabledBgColor: true,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showLeftRightIcon: true,
    isShowNavigationToUrl: false,
  },
  [WidgetNames.ADD_BENEFICIARY_FASTER_PAYMENTS]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    showFontStyle: true,
    showTextInputFontSize: false,
    inputFieldSettings: false,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
  },
  [WidgetNames.INTL_BENEFICIARY_ADDRESS]: {
    showFontStyle: true,
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    showTextInputFontSize: true,
    inputFieldSettings: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
  },
  [WidgetNames.EDIT_INTL_BENEFICIARY_ADDRESS]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    inputFieldSettings: true,
  },
  [WidgetNames.PASSWORD_CHANGE]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    inputFieldSettings: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
  },
  [WidgetNames.PASSWORD_FORGOT]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    inputFieldSettings: true,
  },
  [WidgetNames.PASSWORD_ENTER_EMAIL]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    inputFieldSettings: true,
  },
  [WidgetNames.PASSWORD_UPDATE]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    inputFieldSettings: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
  },
  [WidgetNames.TRIP_INFORMATION_EXIT_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: false,
    inputFieldSettings: true,
  },
  [WidgetNames.PAYMENT_REASON_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: false,
    fontSize: false,
    backGroundColor: false,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: false,
    showPadding: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: false,
    isGlobalStylingWidget: true,
    showInputLabel: true,
  },
  [WidgetNames.PAYMENT_RAIL_SELECTOR_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: false,
    fontSize: false,
    backGroundColor: false,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: false,
    showPadding: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: false,
    isGlobalStylingWidget: true,
    showInputLabel: true,
  },
  [WidgetNames.VIEW_PIN]: {
    color: false,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.MOT_VERIFICATION]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.COMPANY_HOUSE_CHECK]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.CUSTOM_QUESTION_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: true,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: true,
  },
  [WidgetNames.SUMSUB_WIDGET]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
  },
  [WidgetNames.IFRAME_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.SIMPLE_BALANCE_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.BACK_BUTTON_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: true,
    showBoxShadow: true,
    showOverlay: true,
  },
  [WidgetNames.FORWARD_BUTTON_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: true,
    showBoxShadow: true,
    showOverlay: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
  },
  [WidgetNames.TEXT_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
  },
  [WidgetNames.CARD_PIN]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
  },
  [WidgetNames.H1_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: false,
    fontSize: true,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.USER_MANAGEMENT_WIDGET]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: false,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.ACTIVATE_CARD_OTP]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: false,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.BENEFICIARY_CAROUSEL]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.MULTI_ROLE_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    inputFieldSettings: false,
  },

  [WidgetNames.USER_DETAIL_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },

  [WidgetNames.CONTACT_DETAIL_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.IMPORT_USER_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.ACCOUNT_DETAIL_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },

  [WidgetNames.ADDRESS_WIDGET_USER]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.PASSWORD_SETTING_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.ACCOUNT_LIST_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.LOGOUT]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: true,
    disabledBgColor: true,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.SWITCH_NAVIGATOR_QUICK_LINK]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: true,
    disabledBgColor: true,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.BENEFICIARY_SELECTION_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    inputFieldSettings: true,
  },
  [WidgetNames.CONFIRM_BENEFICIARY_DETAILS]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: true,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.LOTTIE_ANIMATION]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    animationSource: true,
    animationAssetLink: true,
    animationUpload: true,
    animationType: true,
    animationContainer: true,
  },
  [WidgetNames.PROFILE_PICTURE_WIDGET]: {
    color: false,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showDimensionSize: true,
  },
  [WidgetNames.NOTIFICATION_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.NOTIFICATION_BELL_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.NOTIFICATION_LIST_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.BIOMETRICS_AUTHENTICATION_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.CURRENCY_ACCOUNTS_LISTING_WIDGET]: accountsListingWidgetsStyles,
  [WidgetNames.LANDING_ACCOUNTS_LISTING]: accountsListingWidgetsStyles,
  [WidgetNames.FEE_CLIENT_ACCOUNT_LISTING_WIDGET]: accountsListingWidgetsStyles,
  [WidgetNames.ACCOUNTS_CONTEXT_DROPDOWN]: {
    color: false,
    hAlign: true,
    vAlign: true,
    border: false,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    showLeftRightIcon: true,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    inputFieldSettings: true,
  },
  [WidgetNames.BULK__PAYMENT_ACCOUNTS_LISTING_WIDGET]: accountsListingWidgetsStyles,
  [WidgetNames.ADD_CURRENCY_ACCOUNT_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    inputFieldSettings: true,
  },

  [WidgetNames.QUESTION_SUMMARY_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: true,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: true,
  },

  [WidgetNames.EXCHANGE_CURRENCY_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: false,
    backGroundColor: true,
    disabledTextColor: true,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    inputFieldSettings: true,
  },

  [WidgetNames.EXCHANGE_QUOTE_WIDGET]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: true,
    showFontStyle: true,
    showTextInputFontSize: false,
    showTextInputBackgroundColor: false,
  },

  [WidgetNames.EXCHANGE_QUOTE_SUMMARY]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: true,
    showFontStyle: true,
    showTextInputFontSize: false,
    showTextInputBackgroundColor: false,
  },

  [WidgetNames.INTERNATIONAL_CURRENCY_COUNTRY]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    inputFieldSettings: true,
  },
  [WidgetNames.EDIT_BENEFICIARY_INTERNATIONAL_CURRENCY_COUNTRY]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    inputFieldSettings: true,
  },
  [WidgetNames.INTERNATIONAL_BENEFICIARY_CREDENTIALS_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: true,
    showFontStyle: true,
    showTextInputFontSize: true,
    showTextInputBackgroundColor: false,
    inputFieldSettings: true,
  },
  [WidgetNames.EDIT_INTERNATIONAL_BENEFICIARY_CREDENTIALS_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: true,
    showFontStyle: true,
    showTextInputFontSize: false,
    showTextInputBackgroundColor: false,
    inputFieldSettings: true,
  },
  [WidgetNames.FILE_UPLOAD_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    showTextInput: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.UPLOAD_FILE_WITH_POPUP_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    showTextInput: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.PROFILE_PICTURE_UPLOAD_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    showTextInput: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.VERIFICATION_ORGANISATION_COMPANY_NAME]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: false,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: false,
    backGroundColor: false,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: false,
    showPadding: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    inputFieldSettings: false,
    isGlobalStylingWidget: true,
    showInputLabel: true,
  },
  [WidgetNames.DOWNLOAD_DOCUMENT_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    showLeftRightIcon: true,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.EDIT_ADDRESS_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    inputFieldSettings: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showTextInputFontSize: true,
  },
  [WidgetNames.EDIT_BENEFICIARY_ADDRESS_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    inputFieldSettings: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showTextInputFontSize: true,
  },
  [WidgetNames.RANGE_CALENDAR_WIDGET]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.OTP_VERIFICATION]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: true,
    inputFieldSettings: true,
    showBasicInput: false,
  },
  [WidgetNames.OTP_VERIFICATION_INTERNATIONAL_PAYMENTS]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: true,
    inputFieldSettings: true,
  },
  [WidgetNames.OTP_VERIFICATION_APPROVER_PAYMENTS]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: true,
    inputFieldSettings: true,
  },
  [WidgetNames.OTP_VERIFICATION_PAYMENT_APPROVAL]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: true,
    inputFieldSettings: true,
  },
  [WidgetNames.INTL_BENEFICIARY_ADDED]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
  },

  [WidgetNames.ASSIGN_PERSONA_ROLE]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    inputFieldSettings: true,
  },
  [WidgetNames.VIEW_USER_ASSIGN_PERSONA]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    inputFieldSettings: true,
  },
  [WidgetNames.PROFILE_PICTURE_DISPLAY]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
  },
  [WidgetNames.EDIT_BENEFICIARY_WIDGET]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    showTextInput: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    inputFieldSettings: true,
  },

  [WidgetNames.DESKTOP_BULK_PAYMENT_HISTORY_LISTING_WIDGET]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    inputFieldSettings: true,
  },
  [WidgetNames.DESKTOP_MANAGE_USER_LISTING_WIDGET]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    inputFieldSettings: true,
  },
  [WidgetNames.DESKTOP_BULK_PAYMENT_LISTING_WIDGET]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showBoxShadow: true,
    inputFieldSettings: true,
  },

  [WidgetNames.OTP_VERIFICATION_BULK_PAYMENTS]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: true,
    inputFieldSettings: true,
  },

  [WidgetNames.ONBOARDING_BACK_NEXT_BUTTON]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: false,
    showOverlay: true,
  },

  [WidgetNames.ADD_EDIT_FEE]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: true,
    inputFieldSettings: true,
  },
  [WidgetNames.FEE_CONFIGURATION_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: true,
    inputFieldSettings: true,
  },

  [WidgetNames.ADD_FEE_GROUP_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: true,
    inputFieldSettings: true,
  },
  [WidgetNames.EU_COUNTRY_DROPDOWN]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: false,
  },
  [WidgetNames.DOCUMENT_PREVIEW]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: false,
    showOverlay: false,
    isShowNavigationToUrl: false,
  },
  [WidgetNames.REVENIR_TRANSACTION_DOCUMENT]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: false,
    showOverlay: false,
    isShowNavigationToUrl: false,
  },
  [WidgetNames.FEE_CLIENT_LISTING_WIDGET]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: true,
  },
  [WidgetNames.FEE_GROUPS_LISTING]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: true,
  },
  [WidgetNames.ASSIGN_GROUP_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    showFontStyle: true,
    showBoxShadow: true,
    isShowRHSWidgetProperty: false,
    inputFieldSettings: true,
  },
  [WidgetNames.REVENIR_TRANSACTION_LIST]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: true,
  },
  [WidgetNames.REVENIR_TRANSACTION_LIST_FIRST_CHARACTER]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    showTextBold: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: false,
  },
  [WidgetNames.REVENIR_TRANSACTION_ITEM_LIST]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: true,
  },
  [WidgetNames.REVENIR_ARCHIVED_TRIPS]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showFontStyle: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: false,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: false,
  },
  [WidgetNames.REVENIR_TRIP_RECLAIM_STATUS_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: false,
    fontSize: false,
    showFontStyle: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: false,
    showBoxShadow: true,
  },
  [WidgetNames.REVENIR_TRIP_RECLAIM_STATUS_CONTENT]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    showFontStyle: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: false,
    showBoxShadow: false,
  },
  [WidgetNames.REVENIR_ARCHIVED_TRIPS_COUNTRY_NAME_CONTENT]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showFontStyle: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: false,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: true,
    showBoxShadow: false,
    showTypographyClass: true,
    showTypographyClassTwo: true,
  },
  [WidgetNames.REVENIR_ARCHIVED_TRIP_CARD_CONTENT]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showFontStyle: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: false,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: false,
    showBoxShadow: true,
  },
  [WidgetNames.REVENIR_ARCHIVED_TRIP_STATUS_CONTENT]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showFontStyle: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: false,
    showBoxShadow: true,
    showTypographyClass: true,
  },
  [WidgetNames.COPY_SHARE_CONTENT]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    showLeftRightIcon: true,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.TRANSACTION_BUTTON]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    showOverlay: false,
  },
  [WidgetNames.TRANSACTION_LIST]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    showOverlay: false,
  },
  [WidgetNames.FREEZE_CARD_TOGGLE_SWITCH]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.CARDS_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    showOverlay: false,
  },
  [WidgetNames.DOCUMENT_UPLOAD_PREVIEW]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    showTextInput: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.UPLOAD_BOARDING_PASS_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    showTextInput: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.CARD_SENSITIVE_DETAILS]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    showOverlay: false,
  },
  [WidgetNames.REVENIR_BARCODE_IMAGE_VIEWER_COMPONENT]: {
    color: true,
    hAlign: true,
    vAlign: false,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    showOverlay: false,
  },
  [WidgetNames.REVIEW_UPLOAD_BOARDING_PASS]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    showTextInputFontSize: false,
    inputFieldSettings: false,
    isShowNavigationToUrl: false,
  },
  [WidgetNames.UPLOAD_PASSPORT_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: true,
    backGroundColor: true,
    showTextInput: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
  },
  [WidgetNames.TIMER_BUTTON]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: true,
    showTextItalic: true,
    showTextUnderline: true,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    isShowNavigationToUrl: false,
    showOverlay: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    inputFieldSettings: true,
    showPrimeNgTable: true,
  },
  [WidgetNames.DESKTOP_PAYMENT_REQUEST_PENDING_APPROVED_LISTING_WIDGET]: {
    color: true,
    hAlign: true,
    vAlign: true,
    border: true,
    fontSize: true,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: true,
    showBoxShadow: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: true,
    inputFieldSettings: true,
    showPrimeNgTable: true,
  },
  [WidgetNames.CARD_NAME_WIDGET]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    inputFieldSettings: false,
    showPrimeNgTable: false,
  },
  [WidgetNames.ADD_TO_GOOGLE_PAY_BUTTON]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: false,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: false,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    inputFieldSettings: false,
    showPrimeNgTable: false,
  },
  [WidgetNames.ADD_TO_APPLE_WALLET_BUTTON]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: false,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: false,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: false,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    inputFieldSettings: false,
    showPrimeNgTable: false,
  },
  [WidgetNames.VERIFICATION_ORG_EDIT_REGISTER_NUMBER]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: false,
    fontSize: false,
    showTextBold: false,
    showTextItalic: false,
    showTextUnderline: false,
    showFontStyle: false,
    showBoxShadow: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: false,
    showPadding: false,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    inputFieldSettings: false,
    showPrimeNgTable: false,
    isGlobalStylingWidget: true,
    showInputLabel: true,
  },
  [WidgetNames.VERIFICATION_ORG_EDIT_INCORPORATION_DATE]: {
    color: false,
    hAlign: false,
    vAlign: false,
    border: false,
    fontSize: false,
    backGroundColor: false,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: false,
    showPadding: false,
    showTextInput: false,
    showQuillInput: false,
    showImageCategoryOption: false,
    iconLibrary: false,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    isGlobalStylingWidget: true,
  },
  [WidgetNames.THEME_SELECTOR]: {
    color: true,
    hAlign: false,
    vAlign: false,
    border: true,
    fontSize: true,
    backGroundColor: true,
    disabledTextColor: false,
    disabledBgColor: false,
    showMargin: true,
    showPadding: true,
    showTextInput: true,
    showQuillInput: false,
    showBoxShadow: true,
    showImageCategoryOption: false,
    iconLibrary: true,
    isShowRHSWidgetProperty: false,
    isShowNavigationToUrl: false,
    isGlobalStylingWidget: false,
  },
} as WidgetStyle;
export type popupName = {
  [key: string]: string;
};

export type ImagePopupSettingConfig = {
  show: boolean;
  type: string;
};

export function updateInputFieldWidgetPropertiesByKey(
  key: string,
  value: string | number | boolean,
  widgetAttributes: WorkflowWidgetConfigurationAttributes,
): InputFieldSettings {
  if (Object.keys(widgetAttributes.widgetProperties.inputFieldSettings?.css_config ?? {}).includes(key)) {
    return JSON.parse(
      JSON.stringify({
        ...widgetAttributes.widgetProperties.inputFieldSettings,
        css_config: {
          ...widgetAttributes.widgetProperties.inputFieldSettings?.css_config,
          [key]: value,
        } as InputCssConfig,
      } as InputFieldSettings),
    );
  } else {
    return JSON.parse(
      JSON.stringify({
        ...widgetAttributes.widgetProperties.inputFieldSettings,
        [key]: value,
      } as InputFieldSettings),
    );
  }
}
