import { LocaleVariableType } from '../../types';
import { keycloakMessages } from './keycloakDefaults';

export const finxoneKeycloakEnGBMessages: LocaleVariableType = {
  category: 'keycloak-i18n',
  locale: 'en-GB',
  content: {
    biometricsButtonText: 'Login via Biometrics',
    /**
     * 
     * Example parser for inserting link:
         * Parses a template string containing [link:text] syntax and replaces with HTML anchor tags
         * @param {string} template - The template string containing [link:text] syntax
         * @param {string} href - The URL to use in the anchor tag
         * @returns {string} - The parsed template with HTML anchor tags
        const parseTemplate = (template, href) => {
          return template.replace(
              /\[link:(.*?)\]/g,
              (_, text) => `<a href="${href}">${text}</a>`
          );
        };
     */
    signUpText: 'Dont have an account? [link:Sign Up]',
    showPin: 'Show App Pin',
    hidePin: 'Hide App Pin',
    appPinLabel: 'Please enter app pin',
    pinCreatedSuccess: 'Your passcode has been created. Please enter it to continue.',
    confirmationPinLabel: 'Please re-enter your passcode',
    loginEnterPinTitle: 'App passcode',
    loginEnterPinSubTitle: 'Enter passcode',
    loginEnterPinForgotText: 'Forgot Passcode?',
    emailOtpAuthInstruction: 'Enter the code we sent to your email address.',

    usernameInputLabel: 'Work email address',
    passwordInputLabel: 'Password',
    savePhoneNavTitle: 'Sign Up',
    emailOtpAuthLabel: 'Enter 6 - digit code that we have sent to your email',
    emailOtpAuthCodeTitle: 'Confirm your email',
    smsOtpHeader: 'Confirm your phone number',
    smsOtpLabel: 'Enter 6 - digit code that we have sent to your mobile number',

    doAccept: 'Agree',
    termsText:
      '["We reserve the right to modify these terms at any time. Any changes will be effective immediately upon posting the updated terms within the App. Your continued use of the App following the posting of changes constitutes your acceptance of those changes.", "We reserve the right to modify these terms at any time. Any changes will be effective immediately upon posting the updated terms within the App. Your continued use of the App following the posting of changes constitutes your acceptance of those changes."]',
    termsNavTitle: 'Sign Up',
    termsSubTitleOne: 'These terms about using your...',
    termsSubTitleTwo: 'Ver.2 Aug 2024',
    termsAgreeStatement: 'I agree with <b>Terms & Conditions</b>',

    loginCreatePinTitle: 'Create passcode',
    loginCreatePinConfirmingTitle: 'Repeat passcode',
    createPinSubTitle: 'Passcode should be 6 digits long',
    createPinNonMatchingError: 'Please ensure you type the same passcode twice',
    phoneNumberLabel: 'Phone number',
    phoneNumberInputPlaceHolder: 'Enter mob number',
    smsSavePhoneSubText: 'You will receive an SMS verification 6 digit code',
    countryPhoneNumberLabel: 'Country code',
    smsSavePhoneHeader: 'Please enter your phone number',
    smsSavePhoneDefaultCountryCode: 'GB',

    createPinNavTitle: 'Sign Up',
    enterPinNavTitle: 'Sign Up',
    loginUsernameNavTitle: 'Sign Up',
    loginEmailOtpNavTitle: 'Sign Up',
    loginSmsOtpNavTitle: 'Sign Up',
    updatePasswordNavTitle: 'Sign Up',
    passwordPlaceholder: 'Enter password',
    updatePasswordTitle: 'Please create a password to login to your account',

    phoneNumberTitle: 'Setup Phone Number',
    phoneNumberInstruction: 'Enter your phone number including the country code at the beginning',
    smsAuthTitle: 'SMS OTP Code',
    smsAuthLabel: 'Code',
    smsAuthInstruction: 'Enter the code we sent to your device.',
    smsAuthSmsNotSent: 'The SMS could not be sent, because of {0}',
    smsAuthCodeExpired: 'The code has expired.',
    smsAuthCodeInvalid: 'Invalid code entered, please enter it again.',
    emailAuthCodeExpired: 'The code has expired.',
    emailAuthCodeInvalid: 'Invalid code entered, please enter it again.',
    confirmUsingBiometricsButtonText: 'Continue using Biometrics',
    appPinConfirmActionPageTitle: 'Hey, {first.name}',
    emailPlaceholder: '{email}',
    alternateMethodPageTitle: 'Try Another Way',
    alternateMethodPageSubTitle:
      'Trouble logging in with your passcode? You can securely sign in using your fingerprint, facial recognition, or passkey.',
    alternateMethodsLabel: 'Authenticate via:',
    alternateMethodNoOptions:
      'No alternative authentication methods are available. Please try again with your passcode.',

    resendFrontText: "Didn't get the code?",
    resendFrontTimerText: ' Resend in ',
    resendBackText: ' sec',
    resendLinkText: ' Resend code',

    totpAuthTitle: 'Authenticator App',
    totpAuthLabel: 'Enter the 6 digit code from your Authentication app',
    ...keycloakMessages,
  },
};
