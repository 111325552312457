<ng-container *ngIf="localeContent$ | async as locale">
  <div
    id="web-app"
    style="background: var(--color-background); height: 100vh"
    class="overflow-y-auto"
    [style.padding-top]="topPadding"
    [style.padding-bottom]="bottomPadding"
  >
    <div class="card-pf">
      <header class="mb-4">
        <div>
          <div *ngIf="companyLogo">
            <div class="is-horizontal-align" [style.padding-top]="isMobile ? '3rem' : '5rem'">
              <div class="Container" [innerHTML]="companyLogo | safeHtml"></div>
            </div>
          </div>
          <div *ngIf="localeValues.title && localeValues.title.length > 0">
            <div>
              <div>
                <div class="d-flex space-evenly mt-4">
                  <div class="text-center align-self-center">
                    <h1>{{ localeValues.title }}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <!-- Error Display -->
      <div *ngIf="error" class="error-message">
        {{ error }}
      </div>
      <ng-container *ngIf="!tryAnotherWay; else tryAnotherWayScreen">
        <!-- Desktop PIN Boxes -->
        <ng-container *ngIf="!isMobile || isConfirmationTypeOTP()">
          <div *ngIf="localeValues">
            <finxone-web-frontend-pin-boxes
              [(pin)]="pin"
              [error]="error"
              (pinBoxes)="checkPinComplete()"
              [confirmationType]="confirmation"
              [localeValues]="localeValues"
            ></finxone-web-frontend-pin-boxes>
          </div>
        </ng-container>

        <!-- Mobile PIN Pad -->
        <ng-container *ngIf="isMobile && !isConfirmationTypeOTP()">
          <finxone-web-frontend-pin-pad
            [(pin)]="pin"
            [error]="error"
            [localeSubtitle]="localeValues.subtitle"
            (pinPad)="checkPinComplete()"
            (biometricAuth)="onBiometricChallenge($event)"
          ></finxone-web-frontend-pin-pad>
        </ng-container>
        <div class="user-info">
          <span class="alt-flow" (click)="onAltFlowClick($event)">{{ locale.doTryAnotherWay }}</span>
        </div>
      </ng-container>
      <ng-template #tryAnotherWayScreen>
        <div
          *ngIf="
            availableConfirmationMethods && availableConfirmationMethods.length > 0;
            else noMethodsAvailable
          "
          class="try-another-way-container"
        >
          <div class="ml-4 mb-2">
            <label class="typog-md font-weight-normal">{{ localeValues.subtitle }}</label>
          </div>
          <div *ngFor="let method of availableConfirmationMethods">
            <div class="mb-4 mx-4">
              <button class="secondaryButton full-width typog-md" (click)="selectAlternateMethod(method)">
                {{ getConfirmationTypeLabel(method) }}
              </button>
            </div>
          </div>
        </div>
        <ng-template #noMethodsAvailable>
          <div class="typog-sm text-center">
            {{ localeValues.doTryAnotherWay }}
          </div>
        </ng-template>
      </ng-template>
      <div class="w-100 d-flex">
        <input
          class="bottom-back button buttonText secondaryButton full-width back-button"
          type="button"
          [value]="localeValues.doBack"
          (click)="!tryAnotherWay ? onBackClick($event) : backFromAlternateMethod()"
        />
      </div>
    </div>
  </div>
</ng-container>
