import { $enum } from 'ts-enum-util';
import { PaymentProviderType } from './cards.type';
import { AlertStatusFlag, CommentStatusFlag } from './common.enum';
import { OnboardingConfigFlowTypes } from './common.type';
import { WidgetNames } from './config.type';
import { CredentialType } from './credential.enum';
import { UISettings } from './ui-settings.type';
import { PageHooks, UiPageConfig } from './ui-system-config.v2.type';
import {
  APP_ZONES,
  APP_ZONES_TITLE,
  BaseWidgetProperties,
  ContainerType,
  GridPosition,
  RightWidgetConfiguration,
  SupportedDevice,
  ValidationRules,
  WORKFLOW_POPUPS,
  WidgetFields,
  ZONETYPEOPTION,
} from './workflow.type';

/*
current possible baas tags:
  modulr - denotes backing BAAS is entirely Modulr based i.e. Confirmation of Payee available etc
  integratedFinance - denotes backing BAAS involes IF as the intermediary before other providers
  if-currencycloud - denotes that IF is using currencyCLoud as it's primarily backing BaaS provider for FX etc

  see logic to parse these in the parseRelevantBaasTags function below
*/
export type RelevantBaasTags = PaymentProviderType | 'if-currencycloud';

export type WorkflowWidgetConfigurationAttributes = {
  // Three properties that must be present others are widget specific config
  widgetProperties: BaseWidgetProperties;
  validationRules?: ValidationRules[];
  elementsConfig: WidgetFields[];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  css_config: any;
  outerCss?: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any;
  linksList?: LinksListType[];
  selectedListOption?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  titleTemplate?: any;
  hideCondition?: string;
  workflowFormConfig?: {
    // self means one form control that widget registers itself
    // page means form is registered elsewhere
    type: 'self' | 'page';
    formPath: string;
  };
};
export type LinksListType = {
  textContent: string;
  pageIndex?: number;
  pageUrl?: string;
  zone: string;
};

export type WorkflowWidgetConfiguration = {
  // Used to display name on left hand side
  widgetDisplayName: string;
  // Used to display name on right hand side
  widgetConfigDisplayName: string;
  // ONLY attributes contains data that should be mapped into SystemConfig
  attributes: WorkflowWidgetConfigurationAttributes;
  isRemovable: boolean;
  isVisible?: boolean;
  uniqueId?: string | number;
  nestedWidgets?: {
    [uniqueId: string]: NestedWorkflowWidgetConfiguration;
  };
};

export type NestedWorkflowWidgetConfiguration = {
  name: WidgetNames;
  // Used to display name on right hand side
  widgetConfigDisplayName: string;
  // ONLY attributes contains data that should be mapped into SystemConfig
  attributes: WorkflowWidgetConfigurationAttributes;
  isRemovable?: boolean;
};

export function isWorkflowWidgetConfiguration(
  config: RightWidgetConfiguration | WorkflowWidgetConfiguration,
): config is WorkflowWidgetConfiguration {
  return (<WorkflowWidgetConfiguration>config).widgetDisplayName !== undefined;
}

export type HookAction = 'add' | 'remove';

export type WorkflowWidgetConfig = NewWorkflowWidgetConfig;

export type CommonWorkflowWidgetConfig = {
  widgetTypeConfig: 'new';
  uniqueId: string | number;
  name: string;
  widgetConfiguration: WorkflowWidgetConfiguration;
  widgetIconPath: string;
  gridPosition?: GridPosition;

  // Used to know what page states to add/remove from Page when this widget is added or removed from page
  alternativeAddWidgets?: WorkflowWidgetConfig[];
  // Used to add/remove extra pages on zone when this widget is added or removed from page
  zoneHook?: string;
  // Used to add/remove/modify extra zones on role when this widget is added or removed from page in a zone
  roleHook?: string;
  // Used to trigger popup when widget is dropped, e.g: custom question, image/carousel widget
  popupType?: WORKFLOW_POPUPS | null;
};

export type NewWorkflowWidgetConfig = CommonWorkflowWidgetConfig & {
  childComponents?: NewWorkflowWidgetConfig[][] | NewWorkflowWidgetConfig[];
};

export function isNewWorkflowWidgetConfig(config: WorkflowWidgetConfig): config is NewWorkflowWidgetConfig {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((<WorkflowWidgetConfig>config) as any)['childComponents'] !== undefined
  );
}

export type CommonPageConfig = {
  mainComponentPageName: string;
  isRemovable: boolean;
  url: string;
  pageType: 'dynamicGrid' | 'staticGrid';
  pageMargin?: {
    left: number;
    right: number;
    top: number;
    bottom?: number;
    unit: string;
  };
  gridsterMargin?: {
    left: number;
    right: number;
    top: number;
    bottom?: number;
    unit: string;
  };
  showHeader?: boolean;
  labelsAsPlaceholder?: boolean;
  hideNavigationMenu: boolean;
  backgroundColor?: string;
  fontsConfig?: {
    [key: string]: { [key: string]: string };
    h1: { 'font-family': string; 'font-weight': string };
    h2: { 'font-family': string; 'font-weight': string };
    h3: { 'font-family': string; 'font-weight': string };
    bodyFont: { 'font-family': string; 'font-weight': string };
  };
  backgroundGradient?: string;
  backgroundSize?: string;
  backgroundImage?: {
    image?: string;
    opacity: string | number;
    objectFitType?: string;
  };
  borderConfig?: {
    width: string;
    color: string;
    unit?: string;
    borderRadius?: {
      topLeft: number;
      topRight: number;
      bottomLeft: number;
      bottomRight: number;
      unit: string;
    };
  };
  backgroundPosition?: {
    x: string;
    y: string;
  };
  isCustomGridActive?: boolean;
  isBodyScrollingEnabled?: boolean;
  isUsingHtmlStructure?: boolean;
  autoRedirectConfig?: {
    isAutoRedirectEnabled?: boolean;
    autoRedirectTimer?: number;
    autoRedirectPersona?: string;
    autoRedirectDestinationZone?: string;
    autoRedirectDestinationPageIndex?: number;
    autoRedirectDestinationPageUrl?: string;
  };
  pageHooks?: PageHooks[];
  modalConfiguration?: ModalConfiguration;
  isAdvancedCssActive?: boolean;
  advancedCss?: string;
};

export type ModalConfiguration = {
  modalSizeMarginUnit: string;
  modalSizeWidth: number;
  modalSizeHeight: number;
  closeExternalClick: boolean;
  closeAction: boolean;
  addBottomSafeAreaPadding?: boolean;
  addTopSafeAreaPadding?: boolean;
  closeIconName: string;
  closeIconTopPosition: number;
  closeIconBottomPosition: number;
  closeIconLeftPosition: number;
  closeIconRightPosition: number;
  closeIconTopPositionUnit: string;
  closeIconBottomPositionUnit: string;
  closeIconLeftPositionUnit: string;
  closeIconRightPositionUnit: string;
  cssEnabled: boolean;
  css: string;
  closeIconColour: string;
  closeIconUnit: string;
  closeIconSize: number;
  backDropColor: string;
  borderWidthUnit: string;
  borderRadiusUnit: string;
  borderRadiusTopLeft: number;
  borderRadiusTopRight: number;
  borderRadiusBottomLeft: number;
  borderRadiusBottomRight: number;
  shadowColour: string;
  shadowBlur: number;
  shadowSpread: number;
  shadowOffsetX: number;
  shadowOffsetY: number;
  marginLeft: number;
  marginRight: number;
  marginTop: number;
  marginBottom: number;
  marginUnit: string;
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  paddingBottom: number;
  paddingUnit: string;
  url: string;
  pageType: string;
  isRemovable: boolean;
  mainComponentPageName: string;
};

export type WorkflowPageConfig = {
  tags?: string[];
  pageConfiguration: CommonPageConfig;
  containerType?: ContainerType;
  name: string; /// Maybe can just be a unique id in future?
  title: string;
  widgets: WorkflowWidgetConfig[];
  pageUniqueId?: string;
};

export type WorkflowZoneConfig = {
  zoneTypeConfig: 'new';
  zoneConfiguration: ZoneConfiguration;
  pages: WorkflowPageConfig[];
};

export type PageTemplateConfig = {
  mobile: WorkflowPageConfig;
  tablet: WorkflowPageConfig;
  desktop: WorkflowPageConfig;
};

export type SystemPageTemplateConfig = {
  [key in SupportedDevice]: UiPageConfig;
};

// use https://www.npmjs.com/package/ts-enum-util
export enum PageTemplateTag {
  DEFAULT = 'DEFAULT',

  FINXONE_PACK = 'FINXONE_PACK',
  CARD_MGMT_PACK = 'CARD_MGMT_PACK',
  NEOBANK_PACK = 'NEOBANK_PACK',
  REVENIR_PACK = 'REVENIR_PACK',

  INTEGRATED_FINANCE = 'INTEGRATED_FINANCE',
  INTEGRATED_FINANCE_CURRENCYCLOUD = 'INTEGRATED_FINANCE_CURRENCYCLOUD',
  INTEGRATED_FINANCE_RAILSR = 'INTEGRATED_FINANCE_RAILSR',
  INTEGRATED_FINANCE_CLEARBANK = 'INTEGRATED_FINANCE_CLEARBANK',
  RAILSR = 'RAILSR',
  ADYEN = 'ADYEN',
  TRADEROOT = 'TRADEROOT',
  MODULR = 'MODULR',
}

/**
 * Maps a page template tag to its display label.
 * Uses $enum utility to map between PageTemplateTag enum values and their corresponding
 * human-readable display labels.
 *
 * @param tag - The template tag to get the label for. Can be undefined.
 * @returns The human-readable label for the tag. Returns "Unspecified (tag)" if tag is undefined.
 */
export function getPageTemplateTagLabel(tag: PageTemplateTag | undefined): string {
  return $enum.mapValue(tag).with({
    [PageTemplateTag.DEFAULT]: 'Default',
    [PageTemplateTag.FINXONE_PACK]: 'Finxone Pack',
    [PageTemplateTag.CARD_MGMT_PACK]: 'Card Management Pack',
    [PageTemplateTag.NEOBANK_PACK]: 'Neobank Pack',
    [PageTemplateTag.REVENIR_PACK]: 'Revenir Pack',
    [PageTemplateTag.INTEGRATED_FINANCE]: 'Clearbank on Integrated Finance',
    [PageTemplateTag.INTEGRATED_FINANCE_CURRENCYCLOUD]: 'Currenycloud on Integrated Finance',
    [PageTemplateTag.INTEGRATED_FINANCE_RAILSR]: 'Railsr on Integrated Finance',
    [PageTemplateTag.INTEGRATED_FINANCE_CLEARBANK]: 'Clearbank on Integrated Finance',
    [PageTemplateTag.RAILSR]: 'Railsr',
    [PageTemplateTag.ADYEN]: 'Adyen',
    [PageTemplateTag.TRADEROOT]: 'Traderoot',
    [PageTemplateTag.MODULR]: 'Modulr',
    [$enum.handleUndefined]: `Unspecified (${tag})`,
  });
}

/**
 * Maps an application zone to its display label.
 * Uses $enum utility to map between APP_ZONES enum values and their corresponding
 * display titles from APP_ZONES_TITLE.
 *
 * @param zone - The zone to get the label for. Can be undefined.
 * @returns The human-readable label for the zone. Returns "Unspecified (zone)" if zone is undefined.
 */
export function getAppZonesLabel(zone: APP_ZONES | undefined): string {
  return $enum.mapValue(zone).with({
    [APP_ZONES.ACCOUNTS_ZONE]: APP_ZONES_TITLE.ACCOUNTS_ZONE,
    [APP_ZONES.BENEFICIARY_ZONE]: APP_ZONES_TITLE.BENEFICIARY_ZONE,
    [APP_ZONES.CARD_MANAGEMENT_ZONE]: APP_ZONES_TITLE.CARD_MANAGEMENT_ZONE,
    [APP_ZONES.CHART]: APP_ZONES_TITLE.CHART,
    [APP_ZONES.FEE_MANAGEMENT]: APP_ZONES_TITLE.FEE_MANAGEMENT,
    [APP_ZONES.GENERAL]: APP_ZONES_TITLE.GENERAL,
    [APP_ZONES.LANDING]: APP_ZONES_TITLE.LANDING,
    [APP_ZONES.LOGIN]: 'Login',
    [APP_ZONES.MENU_ZONE]: APP_ZONES_TITLE.MENU_ZONE,
    [APP_ZONES.ONBOARDING]: APP_ZONES_TITLE.ONBOARDING_PAGE,
    [APP_ZONES.ORGANISATION_ONBOARDING]: APP_ZONES_TITLE.ORGANISATION_ONBOARDING,
    [APP_ZONES.ORGANISATION_PROFILE]: APP_ZONES_TITLE.ORGANISATION_PROFILE,
    [APP_ZONES.ORGANISATION_VERIFICATION]: APP_ZONES_TITLE.ORGANISATION_VERIFICATION,
    [APP_ZONES.PAYMENT]: APP_ZONES_TITLE.PAYMENT,
    [APP_ZONES.PROFILE]: APP_ZONES_TITLE.PROFILE,
    [APP_ZONES.REVENIR_ZONE]: APP_ZONES_TITLE.REVENIR_ZONE,
    [APP_ZONES.SIGNED_OUT_FIRST_INTRODUCTION]: APP_ZONES_TITLE.SIGNED_OUT_FIRST_INTRODUCTION,
    [APP_ZONES.SIGNED_OUT_FORGOT_PASSWORD]: APP_ZONES_TITLE.SIGNED_OUT_FORGOT_PASSWORD,
    [APP_ZONES.SIGNED_OUT_INTRODUCTION]: APP_ZONES_TITLE.SIGNED_OUT_INTRODUCTION,
    [APP_ZONES.TEMPLATE_EDITOR]: 'Template Editor',
    [APP_ZONES.USER_MANAGEMENT]: APP_ZONES_TITLE.USER_MANAGEMENT,
    [APP_ZONES.VERIFICATION]: APP_ZONES_TITLE.VERIFICATION,
    [$enum.handleUndefined]: `Unspecified (${zone})`,
  });
}

export type WorkflowZoneDevicesConfig = {
  mobile: WorkflowZoneConfig | null;
  tablet: WorkflowZoneConfig | null;
  desktop: WorkflowZoneConfig | null;
};

export type ZoneConfiguration = {
  zonePageType: 'tabular' | 'singlePage';
  showNextButton?: boolean;
  zoneType: ZONETYPEOPTION;
  showNextBtn?: boolean;
  showBackBtn?: boolean;
};
export enum DummyRole {
  EMPTY_ROLE = 'EmptyRole',
}

export enum PaymentFlowTypes {
  UK_FASTER_PAYMENTS = 'uk-faster-payments',
  SEPA = 'sepa',
  SWIFT = 'swift',
  CURRENCY_ACCOUNT = 'currency-accounts',
  ALL_PAYMENTS = 'all-payments',
  BULK_PAYMENTS = 'bulk-payments',
  WITH_PERMISSIONS = 'with_permissions',
  WITH_OUT_PERMISSIONS = 'with_out_permissions',
  APPROVER = 'approver',
  REQUESTOR = 'requestor',
  INITIATOR = 'initiator',
  COMMON_UK_FASTER_PAYMENTS_SWIFT = 'common-uk-faster-payments-swift',
}

export enum OrganisationProfileType {
  ORGANISATION_PROFILE = 'organisation-profile',
  FAQ = 'FAQ',
}

export const ProfilePagesTags = {
  DEFAULT_PAGE: 'landing_page',
  USER_PROFILE: 'user_profile',
  SETTINGS: 'settings',
  NOTIFICATIONS_CENTER: 'notifications_center',
  PRIVACY_POLICY: 'privacy_policy',
  FAQS: 'faqs',
  PASSWORD: 'password_manager',
  FEEDBACK_SUPPORT: 'feedback_&_support',
  CURRENCYCLOUD_REGULATIONS: 'regulations_currencycloud',
  BANK_STATEMENTS: 'bank_statements',
} as const;

export const UserManagementPagesTags = {
  USER_DETAILS: 'user_details',
  ADD_USER_AND_PERSONA: 'add_user_and_assign_persona',
} as const;

export enum BeneficiaryZonePagesTags {
  VIEW_BENEFICIARY = 'view_beneficiaries',
  EDIT_BENEFICIARY = 'edit_beneficiaries',
  DELETE_BENEFICIARY = 'delete_beneficiaries',
}

export enum CardManagementZonePageTags {
  PREPAID_VIRTUAL_CARD_TOP_UP = 'prepaid_virtual_card_top_up',
  PREPAID_PHYSICAL_CARD_TOP_UP = 'prepaid_physical_card_top_up',
  DEBIT_PHYSICAL_CARD_CURRENT = 'debit_physical_card_current',
  PREPAID_PHYSICAL_CARD_CURRENT = 'prepaid_physical_card_current',
}
export interface ProjectSettingsRequest {
  projectId: string;
  onboardingFlow: OnboardingConfigFlowTypes;
  defaultCurrency: string;
  smsAuthenticationEnabled: boolean;
  smsAuthenticationExclusion: { [key: string]: string[] };
  checkPayeeEnabled: boolean;
  feeManagementEnabled: boolean;
  retryFailedFeeEnabled: boolean;
  feeReversalEnabled: boolean;
  defaultOtherAlerts?: boolean;
  uiSettings?: UISettings;
  multiOrganisationPaymentApproval?: boolean;
  confirmationPreferenceOrder?: ConfirmationPreferenceTypes[];
}

export enum RevenirZonePagesTags {
  revenir = 'revenir',
}

export interface ProjectAlertToasterRequest {
  projectId: string;
  id: string;
  status: AlertStatusFlag;
}
export interface AppBuilderCommentsRequest {
  comment: string;
  page_id: string;
  persona: string;
  zone: string;
}

export type AppBuilderCommentsListResponse = {
  result: AppBuilderCommentsList[];
  totalItems: number;
  totalPages: number;
  page: number;
  limit: number;
};

export interface AppBuilderCommentsList {
  created_at: string;
  deleted_at: null;
  description: string;
  id: string;
  notification_id: string | null;
  page_id: string;
  persona: string;
  project_id: string;
  status: CommentStatusFlag;
  updated_at: string;
  zone: string;
  user_id: string;
  username: string;
}

export enum ConfirmationPreferenceTypes {
  SMS_OTP = 'sms-otp',
  EMAIL_OTP = 'email-otp',
  APP_PIN = 'app-pin',
  TOTP = 'totp',
  BIOMETRICS = 'biometrics',
}

export enum ConfirmationPreferencesLabel {
  SMS_OTP = 'SMS One-Time Passcode (OTP)',
  EMAIL_OTP = 'Email One-Time Passcode (OTP)',
  APP_PIN = 'App Pin',
  TOTP = 'Authenticator App',
  BIOMETRICS = 'Biometrics',
}
export function getConfirmationPreferenceLabel(
  preference: ConfirmationPreferenceTypes,
): ConfirmationPreferencesLabel {
  return $enum.mapValue(preference).with({
    [ConfirmationPreferenceTypes.SMS_OTP]: ConfirmationPreferencesLabel.SMS_OTP,
    [ConfirmationPreferenceTypes.EMAIL_OTP]: ConfirmationPreferencesLabel.EMAIL_OTP,
    [ConfirmationPreferenceTypes.APP_PIN]: ConfirmationPreferencesLabel.APP_PIN,
    [ConfirmationPreferenceTypes.TOTP]: ConfirmationPreferencesLabel.TOTP,
    [ConfirmationPreferenceTypes.BIOMETRICS]: ConfirmationPreferencesLabel.BIOMETRICS,
  });
}
export function getConfirmationPreferenceType(
  preference: ConfirmationPreferencesLabel,
): ConfirmationPreferenceTypes {
  return $enum.mapValue(preference).with({
    [ConfirmationPreferencesLabel.SMS_OTP]: ConfirmationPreferenceTypes.SMS_OTP,
    [ConfirmationPreferencesLabel.EMAIL_OTP]: ConfirmationPreferenceTypes.EMAIL_OTP,
    [ConfirmationPreferencesLabel.APP_PIN]: ConfirmationPreferenceTypes.APP_PIN,
    [ConfirmationPreferencesLabel.TOTP]: ConfirmationPreferenceTypes.TOTP,
    [ConfirmationPreferencesLabel.BIOMETRICS]: ConfirmationPreferenceTypes.BIOMETRICS,
  });
}

export function getCredentialTypeFromConfirmationPreference(
  preference: ConfirmationPreferenceTypes,
): CredentialType {
  return $enum.mapValue(preference).with({
    [ConfirmationPreferenceTypes.SMS_OTP]: CredentialType.SMS,
    [ConfirmationPreferenceTypes.EMAIL_OTP]: CredentialType.EMAIL,
    [ConfirmationPreferenceTypes.APP_PIN]: CredentialType.PIN,
    [ConfirmationPreferenceTypes.TOTP]: CredentialType.TOTP,
    [ConfirmationPreferenceTypes.BIOMETRICS]: CredentialType.BIOMETRIC,
  });
}
