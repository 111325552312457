import {
  APP_ZONES,
  Constants,
  cryptoRandomUUID,
  GridPosition,
  Page,
  WidgetNames,
  WidgetTitles,
  WORKFLOW_POPUPS,
  WorkflowWidgetConfig,
  WorkflowWidgetConfiguration,
} from '@finxone-platform/shared/sys-config-types';
import { alphaNumericWithSpecialCharsRegEx } from '../../regex.util';

export type CustomWorkflowWidgetConfig = {
  // optional fields to be used in function arguments
  widgetTypeConfig?: 'new';
  uniqueId?: string | number;
  name?: string;
  widgetConfiguration?: WorkflowWidgetConfiguration;
  gridPosition?: GridPosition;
  widgetIconPath?: string;
  alternativeAddWidgets?: WorkflowWidgetConfig[];
  zoneHook?: string;
  roleHook?: string;
  popupType?: WORKFLOW_POPUPS | null;
};

export const lineWidgetWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.LINE_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.LINE_WIDGET,
    widgetConfigDisplayName: 'Spacer : Line',
    isRemovable: true,
    attributes: {
      widgetProperties: {
        textContent: '',
        show: true,
        color: 'white',
        paddingTop: 20,
        paddingBottom: 20,
        lineThickness: 1,
        lineWidth: 90,
        contentAlignment: 'center',
      },
      css_config: {},
      elementsConfig: [
        {
          field_name: WidgetNames.LINE_WIDGET,
          label: 'line',
          type: 'text',
          section: WidgetNames.LINE_WIDGET,
          show: true,
          options: [
            {
              field_type: 'line',
              field_name: WidgetNames.LINE_WIDGET,
              label: 'line',
              type: 'text',
              show: true,
            },
          ],
        },
      ],
      label: '',
      sub_heading: '',
      show: true,
      field_attributes: [
        {
          name: WidgetNames.LINE_WIDGET,
          label: 'line',
          type: 'text',
          options: [],
          decision_config: {
            required: true,
          },
          additional_config: {
            show: true,
            color: 'white',
            paddingTop: 20,
            paddingBottom: 20,
            lineThickness: 1,
            lineWidth: 90,
            contentAlignment: 'center',
          },
        },
      ],
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 2,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const currencyInputFieldWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.CURRENCY_INPUT_FIELD_WIDGET,
  widgetConfiguration: {
    uniqueId: cryptoRandomUUID(),
    widgetDisplayName: WidgetTitles.CURRENCY_INPUT_FIELD_WIDGET,
    widgetConfigDisplayName: 'Input Field: Amount',
    isRemovable: true,
    attributes: {
      inputMode: 'decimal',
      elementsConfig: [],
      label: '',
      sub_heading: '',
      show: true,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        inputPlaceholderColor: 'rgb(0, 0, 0)',
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        fontSize: 40,
        textAlignment: 'center',
      },
      widgetProperties: {
        textContent: '',
        inputPlaceholder: '0.00',
        inputFieldKey: 'amount',
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 8,
    minItemCols: 1,
    minItemRows: 1,
    rows: 2,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export const paymentRefConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.PAYMENT_REF_WIDGET,
  widgetConfiguration: {
    uniqueId: cryptoRandomUUID(),
    widgetDisplayName: WidgetTitles.PAYMENT_REF_WIDGET,
    widgetConfigDisplayName: 'Payment Reference',
    isRemovable: false,
    attributes: {
      elementsConfig: [],
      label: '',
      sub_heading: '',
      show: true,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontSize: 14,
        textAlignment: 'left',
        borderWidth: 1,
        borderColor: 'rgba(197, 197, 197, 1)',
        borderStyle: 'solid',
        inputPlaceholderColor: 'rgba(94, 101, 102, 1)',
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        borderRadiusTopLeft: 2,
        borderRadiusBottomLeft: 2,
        borderRadiusTopRight: 2,
        borderRadiusBottomRight: 2,
      },
      widgetProperties: {
        textContent: '',
        displayName: 'Payment reference',
        inputPlaceholder: 'Payment Reference',
        inputFieldKey: 'paymentReference',
        pattern: alphaNumericWithSpecialCharsRegEx,
        inputLabel: 'Payment Reference',
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 11,
    minItemCols: 1,
    minItemRows: 1,
    rows: 3,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export const paymentReasonWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.PAYMENT_REASON_WIDGET,
  widgetConfiguration: {
    uniqueId: cryptoRandomUUID(),
    widgetDisplayName: WidgetTitles.PAYMENT_REASON,
    widgetConfigDisplayName: 'Payment Reason',
    isRemovable: true,
    attributes: {
      elementsConfig: [],
      label: '',
      sub_heading: '',
      show: true,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        fontSize: 14,
        textAlignment: 'left',
        borderWidth: 1,
        borderColor: 'var(--border-color-1)',
        borderStyle: 'solid',
        inputPlaceholderColor: 'rgba(94, 101, 102, 1)',
        shadowBlur: 0,
        shadowSpread: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        borderRadiusTopLeft: 2,
        borderRadiusBottomLeft: 2,
        borderRadiusTopRight: 2,
        borderRadiusBottomRight: 2,
      },
      widgetProperties: {
        textContent: '',
        inputPlaceholder: 'Payment Reason',
        inputFieldKey: 'transferReasonId',
        inputLabel: 'Payment Reason',
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 15,
    minItemCols: 1,
    minItemRows: 1,
    rows: 3,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export function getCTAButtonsWidgetConfig(
  type: WidgetNames,
  title: WidgetTitles | string,
): WorkflowWidgetConfig {
  return {
    widgetTypeConfig: 'new',
    uniqueId: cryptoRandomUUID(),
    name: type,
    widgetConfiguration: {
      uniqueId: cryptoRandomUUID(),
      widgetDisplayName: title,
      widgetConfigDisplayName: 'CTA Button',
      isRemovable: false,
      attributes: {
        elementsConfig: [],
        label: '',
        sub_heading: '',
        show: true,
        css_config: {
          ...Constants.defaultBaseCssConfig,
          textAlignment: 'center',
          backGroundColor: 'var(--palette-color-3)',
          color: 'var(--palette-color-6)',
          fontSize: 16,
          shadowBlur: 0,
          shadowSpread: 0,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          borderRadiusTopLeft: 5,
          borderRadiusBottomLeft: 5,
          borderRadiusTopRight: 5,
          borderRadiusBottomRight: 5,
        },
        widgetProperties: {
          textContent: 'Next',
          zoneToNavigate: APP_ZONES.PAYMENT,
          urlToNavigate: 'payment-summary',
        },
      },
    },
    gridPosition: {
      x: 0,
      y: 0,
      minItemCols: 1,
      minItemRows: 1,
      rows: 3,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    },
    widgetIconPath: 'generic-widget',
  };
}

export const textWidgetWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.TEXT_WIDGET,
  widgetConfiguration: {
    uniqueId: cryptoRandomUUID(),
    widgetDisplayName: WidgetTitles.TEXT_WIDGET,
    widgetConfigDisplayName: 'Text / HTML',
    isRemovable: true,
    attributes: {
      elementsConfig: [],
      label: '',
      sub_heading: '',
      show: true,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'center',
        verticalAlignment: 'center',
        fontSize: '16',
      },
      widgetProperties: {
        textContent: 'Text / HTML',
        textStyleClass: 'body1',
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 2,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'text-widget',
} as WorkflowWidgetConfig;

export function createTextWidgetConfig(
  textContent: string,
  textAlignment: string,
  color: string,
  fontSize: string,
  displayName?: string,
  rows = 2,
  cols = 15,
): WorkflowWidgetConfig {
  return {
    widgetTypeConfig: 'new',
    uniqueId: cryptoRandomUUID(),
    name: WidgetNames.TEXT_WIDGET,
    widgetConfiguration: {
      uniqueId: cryptoRandomUUID(),
      widgetDisplayName: WidgetTitles.TEXT_WIDGET,
      widgetConfigDisplayName: displayName ?? 'Text / HTML',
      isRemovable: true,
      attributes: {
        elementsConfig: [],
        label: '',
        sub_heading: '',
        show: true,
        css_config: {
          ...Constants.defaultBaseCssConfig,
          textAlignment: textAlignment,
          verticalAlignment: 'center',
          color: color,
          fontSize: fontSize,
        },
        widgetProperties: {
          textContent: textContent,
        },
      },
    },
    gridPosition: {
      x: 0,
      y: 0,
      minItemCols: 1,
      minItemRows: 1,
      rows: rows,
      cols: cols,
      resizeEnabled: true,
      dragEnabled: true,
    },
    widgetIconPath: 'text-widget',
  } as WorkflowWidgetConfig;
}

export const h1WidgetWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.H1_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.H1_WIDGET,
    widgetConfigDisplayName: 'H1: Text',
    isRemovable: true,
    attributes: {
      elementsConfig: [],
      label: '',
      sub_heading: '',
      show: true,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        textAlignment: 'center',
        verticalAlignment: 'center',
        fontSize: '32',
      },
      widgetProperties: {
        textContent: 'Header Text',
      },
    },
    page: WidgetNames.H1_WIDGET,
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 1,
    minItemRows: 1,
    rows: 2,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const iFrameWidgetWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.IFRAME_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.IFRAME_WIDGET,
    widgetConfigDisplayName: 'iFrame',
    isRemovable: true,
    attributes: {
      widgetProperties: {
        textContent: '',
        iframeUrl: '',
        verticalHeight: '30',
        showIFrameWidget: true,
      },
      elementsConfig: [
        {
          field_name: WidgetNames.IFRAME_WIDGET,
          label: 'iframe',
          type: 'text',
          section: WidgetNames.IFRAME_WIDGET,
          show: true,
          options: [
            {
              field_type: 'iframe',
              field_name: WidgetNames.IFRAME_WIDGET,
              label: 'iframe',
              type: 'text',
              show: true,
            },
          ],
        },
      ],
      label: '',
      sub_heading: '',
      show: true,
      field_attributes: [
        {
          name: WidgetNames.IFRAME_WIDGET,
          label: 'iframe',
          type: 'text',
          options: [],
          decision_config: {
            required: true,
          },
          additional_config: {},
        },
      ],
      css_config: {
        ...Constants.defaultBaseCssConfig,
      },
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 5,
    minItemRows: 2,
    rows: 6,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export const imageWidgetWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.IMAGE_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.IMAGE_WIDGET,
    widgetConfigDisplayName: 'Single Image',
    isRemovable: true,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [
        {
          field_name: 'image_widget',
          label: 'Image.png',
          type: 'file',
          section: 'image_widget',
          show: true,
          options: [],
        },
      ],
      css_config: Constants.defaultBaseCssConfig,
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 5,
    minItemRows: 2,
    rows: 4,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'image-widget',
  popupType: WORKFLOW_POPUPS.IMAGE_WIDGET,
} as WorkflowWidgetConfig;

export const carouselWidgetWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: Math.floor(Math.random() * (1000000000 + 1)),
  name: WidgetNames.CAROUSEL_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.CAROUSEL_WIDGET,
    widgetConfigDisplayName: 'Carousel.png',
    isRemovable: true,
    attributes: {
      widgetProperties: { textContent: '' },
      elementsConfig: [
        {
          field_name: WidgetNames.CAROUSEL_WIDGET,
          label: 'Carousel.png',
          type: 'file',
          section: WidgetNames.CAROUSEL_WIDGET,
          show: true,
          timer: 3,
          options: [],
        },
      ],
      css_config: Constants.defaultBaseCssConfig,
    },
  },
  gridPosition: {
    x: 0,
    y: 0,
    minItemCols: 5,
    minItemRows: 2,
    rows: 10,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
  popupType: WORKFLOW_POPUPS.CAROUSEL_WIDGET,
} as WorkflowWidgetConfig;

export function getProgressBarWidgetConfig(customConfig?: CustomWorkflowWidgetConfig): WorkflowWidgetConfig {
  const progressBarWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId: cryptoRandomUUID(),
    name: WidgetNames.PROGRESSBAR_WIDGET,
    widgetConfiguration: {
      widgetDisplayName: WidgetTitles.PROGRESSBAR_WIDGET,
      widgetConfigDisplayName: WidgetTitles.PROGRESSBAR_WIDGET,
      isRemovable: true,
      attributes: {
        widgetProperties: {
          textContent: WidgetTitles.PROGRESSBAR_WIDGET,
          completionProgressPercentage: '5',
          progressBarActiveColor: 'var(--palette-color-0)',
          progressBarColor: 'var(--palette-color-6)',
        },
        elementsConfig: [
          {
            field_name: WidgetNames.PROGRESSBAR_WIDGET,
            label: 'Image.png',
            type: 'file',
            section: WidgetNames.PROGRESSBAR_WIDGET,
            show: true,
            options: [],
          },
        ],
        css_config: {
          ...Constants.defaultBaseCssConfig,
          color: 'var(--typography-color-0)',
          backGroundColor: 'var(--palette-color-3)',
          textAlignment: 'center',
          verticalAlignment: 'end',
          fontSize: 25,
          isTextBold: true,
        },
      },
    },
    gridPosition: {
      x: 0,
      y: 0,
      minItemCols: 5,
      minItemRows: 4,
      rows: 4,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    },
    widgetIconPath: 'generic-widget',
    popupType: WORKFLOW_POPUPS.IMAGE_WIDGET,
  };

  return {
    ...progressBarWidgetConfig,
    ...customConfig,
  } as WorkflowWidgetConfig;
}
export function createWorkflowWidgetConfig(
  leftIcon: string,
  itemTemplate: string,
  pageName: Page,
  widgetTitle: WidgetTitles | string,
  rightIcon?: string,
  horizentalAlignment?: string,
): CustomWorkflowWidgetConfig {
  return {
    widgetConfiguration: {
      widgetDisplayName: widgetTitle,
      widgetConfigDisplayName: widgetTitle,
      attributes: {
        elementsConfig: [],
        name: pageName,
        type: 'links',
        slug: pageName,
        label: widgetTitle,
        zone: '',
        role: '',
        css_config: {
          ...Constants.defaultBaseCssConfig,
          textAlignment: horizentalAlignment ?? 'center',
          verticalAlignment: 'center',
          textFont: 'Metropolis',
          fontSize: 20,
          shadowBlur: 0,
          shadowSpread: 0,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          borderWidth: '1',
          borderColor: '#EBEBEB',
          backGroundColor: '#fff',
          color: '#384F80',
        },
        widgetProperties: {
          textContent: '',
        },
        pageUrl: '',
        iconConfig: {
          leftIcon,
          rightIcon: rightIcon ?? '',
        },
        itemConfig: {
          itemTemplate,
        },
      },
      isRemovable: true,
    } as WorkflowWidgetConfiguration,
  };
}

export const copyShareContentWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.COPY_SHARE_CONTENT,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.COPY_SHARE_CONTENT,
    widgetConfigDisplayName: 'Copy/Share Content',
    attributes: {
      elementsConfig: [],
      showShareSheet: false,
      textContent: {
        textContentToDisplay: 'Copy Details',
        textContentToCopy: '',
      },
      css_config: {
        ...Constants.defaultBaseCssConfig,
        color: 'var(--palette-color-6)',
        backGroundColor: 'var(--palette-color-3)',
        textAlignment: 'center',
        justifyContent: 'center',
        verticalAlignment: 'center',
        borderColor: 'var(--palette-color-3)',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadiusTopLeft: 6,
        borderRadiusBottomLeft: 6,
        borderRadiusTopRight: 6,
        borderRadiusBottomRight: 6,
        textFont: 'var(--font-family)',
        fontSize: 20,
        display: 'flex',
      },
      widgetProperties: {
        textContent: '',
        righticon: 'copy-icon',
        lefticon: '',
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 1,
    minItemCols: 1,
    minItemRows: 1,
    rows: 2,
    cols: 15,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: 'generic-widget',
} as WorkflowWidgetConfig;

export function addToGooglePayWidgetConfig(): WorkflowWidgetConfig {
  const WidgetConfig: WorkflowWidgetConfig = {
    widgetTypeConfig: 'new',
    uniqueId: cryptoRandomUUID(),
    name: WidgetNames.ADD_TO_GOOGLE_PAY_BUTTON,
    widgetConfiguration: {
      widgetDisplayName: WidgetTitles.ADD_TO_GOOGLE_PAY_BUTTON,
      widgetConfigDisplayName: 'Add to Google Pay',
      attributes: {
        elementsConfig: [],
        textContent: {
          textContentToDisplay: '',
          textContentToCopy: '',
        },
        css_config: {
          marginUnit: 'px',
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 0,
          borderRadius: 0,
          width: 335,
          height: 44,
          textWidth: 92,
          textHeight: 18,
        },
        widgetProperties: {
          textContent: '',
          buttonColorType: '#000000',
        },
      },
      isRemovable: true,
    },
    gridPosition: {
      x: 0,
      y: 1,
      minItemCols: 1,
      minItemRows: 1,
      rows: 2,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    },
    widgetIconPath: 'generic-widget',
  };
  return WidgetConfig;
}

export function addToAppleWalletWidgetConfig(): WorkflowWidgetConfig {
  return {
    widgetTypeConfig: 'new',
    uniqueId: cryptoRandomUUID(),
    name: WidgetNames.ADD_TO_APPLE_WALLET_BUTTON,
    widgetConfiguration: {
      widgetDisplayName: WidgetTitles.ADD_TO_APPLE_WALLET_BUTTON,
      widgetConfigDisplayName: 'Add to Apple Wallet',
      attributes: {
        elementsConfig: [],
        textContent: {
          textContentToDisplay: '',
          textContentToCopy: '',
        },
        css_config: {
          marginUnit: 'px',
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0,
          marginBottom: 0,
          paddingUnit: 'px',
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
          width: 193,
        },
        widgetProperties: {
          textContent: '',
        },
      },
      isRemovable: true,
    },
    gridPosition: {
      x: 0,
      y: 1,
      minItemCols: 1,
      minItemRows: 1,
      rows: 3,
      cols: 15,
      resizeEnabled: true,
      dragEnabled: true,
    },
    widgetIconPath: 'generic-widget',
  };
}
