import { CardInfoType, CardStatusType, CardType } from '@finxone-platform/shared/sys-config-types';
import { isIOS } from './device-detection.utils';
import { capitalize } from './utils';

/**
 * Common function to pass `loadEvent` from various point in the app
 * @param event
 */
export function logLoadEventToFlutter(event: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const flutterWebView = (window as any).flutter_inappwebview;
  if (flutterWebView) {
    flutterWebView.callHandler('loadEvent', [event]);
  }
}

/**
 * Passes active cards to native storage for Apple Wallet Extension
 * @param cardList
 */
export function passActiveCardsToNativeStorage(cardList: CardInfoType[]): void {
  if (!isIOS() || !cardList.length) return;

  const walletReadyCards: Record<string, ProvisioningCredential> = cardList
    .filter((card) => card.status.toLowerCase() === CardStatusType.ACTIVE)
    .reduce((acc, card) => {
      acc[card.id] = mapToProvisioningCredential(card);
      return acc;
    }, {} as Record<string, ProvisioningCredential>);

  const flutterWebView = (window as any).flutter_inappwebview;
  if (flutterWebView) {
    flutterWebView.callHandler('setCardListHandler', [walletReadyCards]);
  }
}

/**
 * Maps card details to ProvisioningCredential schema needed by Apple Wallet
 */
function mapToProvisioningCredential(card: CardInfoType): ProvisioningCredential {
  return {
    isAvailableForProvisioning: true, // already checked 'active'
    primaryAccountIdentifier: card.id,
    primaryAccountSuffix: card.maskedPan.replace(/[^0-9]/g, '').slice(-4),
    localizedDescription: `${card.currency} ${capitalize(card.type)} Card`,
    cardholderName: card.cardholderName || '',
    expiration: card.expiry,
    assetName: determineAssetName(card.type),
    label: card.name || '',
  };
}

/**
 * Helper function to determine the appropriate card art asset
 */
function determineAssetName(cardType: string): string {
  switch (cardType.toLowerCase()) {
    case CardType.VIRTUAL:
      return 'virtual_card';
    case CardType.PHYSICAL:
      return 'physical_card';
    default:
      return 'generic_card';
  }
}

/**
 * Required fields for ProvisioningCredential structure for Apple Wallet
 */
interface ProvisioningCredential {
  isAvailableForProvisioning: boolean;
  primaryAccountIdentifier: string;
  primaryAccountSuffix: string;
  localizedDescription: string;
  cardholderName: string;
  expiration: string;
  assetName: string;
  label: string;
}
